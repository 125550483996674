import React, { useState } from "react";
import { Link } from "react-router-dom";

function Login() {
  const error = document.getElementById("error-alert");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const hadnleSubmit = async (e) => {
    e.preventDefault();
    const connect = await fetch("http://localhost:5000", {
      method: "POST",
      body: JSON.stringify({ email, password }),
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    });
    const response = await connect.json();
    console.log(response);
    handleErrors(connect);
  };

  const handleErrors = (connect) => {
    if (connect.status === 404) {
      error.innerText = "User not found";
    } else if (connect.status === 401) {
      error.innerText = "Invalid Password";
    } else if (connect.status === 200) {
      error.innerText = "Login Successful";
    } else {
      error.innerText = "Internal Server Error";
    }
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="card mt-5 mx-auto" style={{ width: "400px" }}>
            <h5 className="card-header h3">Login</h5>
            <div className="card-body">
              <div id="error-alert" className="alert alert-warning"></div>
              <form onSubmit={hadnleSubmit}>
                <div className="form-group">
                  <label htmlFor="inputEmail">Email address</label>
                  <input
                    type="email"
                    className="form-control"
                    id="inputEmail"
                    aria-describedby="emailHelp"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    We'll never share your email with anyone else.
                  </small>
                </div>
                <div className="form-group">
                  <label htmlFor="inputPassword">Password</label>
                  <input
                    type="password"
                    className="form-control"
                    id="inputPassword"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="submit-btn">
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                  <Link to="/register" className="btn btn-success mx-2">
                    Not a member? Register now!
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
