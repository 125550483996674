import React from "react";

function Profile(props) {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6">
          <div className="card">
            <div className="card-body">
              <p className="card-title">Hello and Welcome Mr. {props.name}</p>
              <p className="card-text">
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Fugit
                placeat optio quam numquam doloribus asperiores dicta autem
                delectus molestias expedita aspernatur aut debitis saepe, alias
                tempore incidunt quis. Amet, corporis!
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;
