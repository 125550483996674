import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/PrivateRoute";
import Login from "./components/Login";
import Register from "./components/Register";
import Profile from "./components/Profile";

function App() {
  return (
    <Router>
      <Switch>
        <PrivateRoute path="/profile" component={Profile} exact={true} />
        <Route path="/register" component={Register} exact={true}></Route>
        <Route path="/" component={Login} exact={true}></Route>
      </Switch>
    </Router>
  );
}

export default App;
